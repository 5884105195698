<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`${keyPrefix}-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
// common
import {
  LibrarySeparator,
  Theme3BaseHeaderOne,
  Theme3ComponentsBanner,
} from '#components';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },

    keyPrefix: {
      required: false,
      type: String,
      default: 'dynamicComponent',
    },
  },

  components: {
    TheHeader: Theme3BaseHeaderOne,
    Banner: Theme3ComponentsBanner,
    Separator: LibrarySeparator,
  },
});
</script>
