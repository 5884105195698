<template>
  <available-components-for-header
    v-if="headerMenuComponents && headerMenuComponents.length && !isAnythingOnTopVisible"
    :specific-components="headerMenuComponents"
  />
</template>

<script lang="ts">
import AvailableComponentsForHeader from '~/components/theme3/AvailableComponentsForHeader.vue';
import { TheHeaderMixin } from '~/units/core/mixins/theHeader.mixin';
import { mapState } from 'pinia';
import { useCoreStore } from '~/units/core/store';

export default defineNuxtComponent({
  name: 'Theme3BaseHeaderTheHeader',
  components: { AvailableComponentsForHeader },
  mixins: [TheHeaderMixin],

  computed: {
    ...mapState(useCoreStore, ['isAnythingOnTopVisible']),
  }
});
</script>
