<template>
  <div :style="cssVariablesBinding">
    <Theme3BaseTheHeader />

    <div id="special-place" class="pxp-content main-body"></div>

    <main v-if="!activeContainer" class="pxp-content main-body tw-pb-12">
      <slot />
    </main>
    <div
      v-else-if="activeContainer && activeContainer.name === 'BlogDetail'"
      class="pxp-content main-body tw-flex tw-justify-center tw-pb-12"
    >
      <div
        class="container tw-grid tw-grid-cols-12 max-[991px]:tw-max-w-full max-md:tw-m-0 lg:tw-gap-x-10"
      >
        <div class="tw-col-span-12 lg:tw-col-span-7 xl:tw-col-span-8">
          <slot />
        </div>
        <div
          class="tw-col-span-12 lg:tw-col-span-5 lg:tw-py-[200px] xl:tw-col-span-4"
          style="z-index: 1"
        >
          <theme3-available-components-for-sidebar
            :specific-components="sidebarComponents"
            key-prefix="sidebar"
          ></theme3-available-components-for-sidebar>
        </div>
      </div>
    </div>

    <Theme3BaseTheFooter />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import IndexGlobal from '~/mixins/index.global';
import LayoutsMixin from '~/mixins/layouts.mixin';
import Theme3BaseFooterOne from '~/components/theme3/base/footer/FooterOne.vue';
import { useWebsiteStore } from '~/units/website/store';
import AvailableComponentsForTheme3 from '~/components/theme3/AvailableComponentsForTheme3.vue';
import { CompanyKeys } from '~/units/website/constants';

export default defineNuxtComponent({
  name: 'Theme3',

  mixins: [IndexGlobal, LayoutsMixin],

  components: { AvailableComponentsForTheme3, Theme3BaseFooterOne },

  data() {
    return {
      containers: [
        {
          name: 'BLOGS_VIEW',
          classes: 'blog-wrap5',
        },
        {
          name: 'BLOG_DETAIL_VIEW',
          classes: 'blog-wrap6',
        },
      ],
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['currentPage']),

    companyFont() {
      if (this.company?.company_key === CompanyKeys.BROWNS_ESTATE_AGENTS) {
        return 'PlusJakartaSans, sans-serif';
      } else if (this.company?.company_key === CompanyKeys.NEIL_KING) {
        return 'GothamRounded';
      }
      return '';
    },

    cssVariablesBinding() {
      return {
        ...this.cssVariables,
        '--nc-ternary-color': this.colors.primary,
        '--nc-ternary-bg-color': this.colors.secondary,
        '--nc-secondary-bg-color': this.colors.ternary,
        '--nc-white-color': this.colors.white,
        '--nc-raisin-color': this.colors.raisin,
        '--nc-camel-color': this.colors.camel,
        '--nc-purple-color': this.colors.purple,
        '--nc-isabelline-color': this.colors.isabelline,
        '--checkbox-tick-color': 'white',
        '--nc-tenant': '#63BFBC',
        '--nc-buyer': '#f2c94c',
        '--nc-border-radius': '0',
        '--default-font': this.companyFont,
        '--default-title-font': this.companyFont,
        '--form-title-color': '',
        '--box-checked-color': this.colors.camel,
        '--checkbox-checked-color': this.colors.purple,
        '--default-border-color': '#DDDDDD',
        '--form-errors-background-color': this.colors.purple,
        '--form-errors-text-color': this.colors.white,
        '--nc-doughnut-chart-value': this.colors.camel,
        '--nc-classic-black': '#222222',
        '--nc-menu-hover-text-color': this.colors.camel,
        '--pagination-border-color': this.colors.camel,
        '--nc-primary-button-bg-color': this.colors.purple,
        '--nc-primary-button-radius': '.25rem',
        '--nc-property-status-bg-color': this.colors.camel,
        '--nc-property-status-text-color': this.colors.white,
        '--nc-mini-title-color': '#666666',
        '--nc-popup-background-color': this.colors.isabelline,
        '--nc-sub-menu-text-color': this.colors.black,

        '--nc-date-picker-selected-bg-color': this.colors.purple,
        '--nc-date-picker-selected-text-color': this.colors.white,
      };
    },
  },

  head(context) {
    if (process.server && context.ssrContext) {
      const websiteStore = useWebsiteStore();

      return {
        link: [
          {
            href: '/theme2/css/bootstrap.min.css',
            rel: 'stylesheet',
            hid: 'css/bootstrap.min.css',
            type: 'text/css',
          },
          {
            href: '/theme3/css/owl.theme.min.css',
            rel: 'stylesheet',
            hid: 'css/owl.theme.min.css',
            type: 'text/css',
          },
          {
            href: '/theme3/css/owl.carousel.min.css',
            rel: 'stylesheet',
            hid: 'css/owl.carousel.min.css',
            type: 'text/css',
          },
          {
            href: '/cdn/fontawesome/css/all.css',
            rel: 'stylesheet',
            hid: 'css/owl.theme.carousel.min.css',
            type: 'text/css',
          },
          {
            href: '/theme3/css/style.css',
            rel: 'stylesheet',
            hid: 'css/style.css',
            type: 'text/css',
          },
          {
            href: '/theme3/css/custom.css',
            rel: 'stylesheet',
            hid: 'css/custom.css',
            type: 'text/css',
          },
          {
            href: `/theme3/css/${websiteStore.companyKey}.css`,
            rel: 'stylesheet',
            hid: `css/${websiteStore.companyKey}.css`,
            type: 'text/css',
          },
        ],
        script: [
          {
            hid: 'jquery-3.4.1',
            type: 'text/javascript',
            src: `/theme3/js/jquery-3.4.1.min.js`,
            body: true,
            async: false,
          },
          {
            hid: 'popper',
            type: 'text/javascript',
            src: `/theme3/js/popper.min.js`,
            body: true,
            async: false,
          },
          {
            hid: 'owl-carousel',
            type: 'text/javascript',
            src: `/theme3/js/owl.carousel.min.js`,
            body: true,
            async: false,
          },
          {
            hid: 'main',
            type: 'text/javascript',
            src: `/theme3/js/main.js`,
            body: true,
            async: false,
          },
        ],
      };
    }
  },
});
</script>

<style scoped>
.main-body {
  background-color: var(--nc-isabelline-color);
}

@media (min-width: 1300px) {
  ::v-deep(
      .container,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl
    ) {
    max-width: 1280px;
  }
}
</style>
